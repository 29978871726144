<template>
  <section class="blog">
    <div class="container" style="max-width: 1100px">
      <div class="blog-section-content">
        <div class="blog-title">
          <h1>{{ selectedContent.title }}</h1>
        </div>
        <!-- <div class="row"> -->
        <!-- <div class="col-lg-8"> -->
        <div class="blog-detail-page">
          <div class="blog-image">
            <iframe
              width="100%"
              height="600"
              :src="
                'https://www.youtube.com/embed/' + selectedContent.video_link
              "
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
            <!-- <img
                src="https://images.unsplash.com/photo-1517694712202-14dd9538aa97?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80"
                alt=""
              /> -->
            <!-- <img :src="selectedContent.image" alt="" /> -->

            <p>
              {{ selectedContent.publish_date }} |
              {{ selectedContent.category }}
            </p>
          </div>

          <div class="blog-contact-details">
            <div class="contact-content">
              <div class="contact-img">
                <img :src="selectedContent.image" alt="" />
              </div>

              <div class="contact-info">
                <div class="details-content-name">
                  <h1>Surendra Maharjan</h1>
                  <div class="contact-time">
                    <p>Apr 5, 2019</p>
                    <p>3 min read</p>
                    <a href="">
                      <p class="colored-text">
                        <i class="fas fa-play-circle"></i> Listen
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="detail-icon">
              <i class="far fa-bookmark"></i>
            </div>
          </div>
          <div class="blog-details">
            <div class="blog-details-title">
              <!-- <h1>{{ selectedContent.title }}</h1> -->
              <p>
                {{ selectedContent.description }}
              </p>
              <p class="detail-paragraph">
                I am alone, and feel the charm of existence in this spot, which
                was created for the bliss of souls like mine. I am so happy, my
                dear friend, so absorbed in the exquisite sense of mere tranquil
                existence, that I neglect my talents. I should be incapable of
                drawing a single stroke at the present moment; and yet I feel
                that I never was a greater artist than now.
              </p>
            </div>
          </div>
        </div>
        <!-- </div> -->

        <!-- <div class="col-lg-4">
          <div class="aside-section">
            <h1>Text Widget</h1>
            <p>
              Nulla vitae elit libero, a pharetra augue. Nulla vitae elit
              libero, a pharetra augue. Nulla vitae elit libero, a pharetra
              augue. Donec sed odio dui. Etiam porta sem malesuada.
            </p>

            <v-row justify="center">
              <v-dialog v-model="dialog" width="800">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Use Google's location service?</span>
                  </v-card-title>
                  <v-card-text>
                    Lorem ipsum dolor sit amet, semper quis, sapien id natoque
                    elit. Nostra urna at, magna at neque sed sed ante imperdiet,
                    dolor mauris cursus velit, velit non, sem nec. Volutpat sem
                    ridiculus placerat leo, augue in, duis erat proin
                    condimentum in a eget, sed fermentum sed vestibulum varius
                    ac, vestibulum volutpat orci ut elit eget tortor. Ultrices
                    nascetur nulla gravida ante arcu. Pharetra rhoncus morbi
                    ipsum, nunc tempor debitis, ipsum pellentesque, vitae id
                    quam ut mauris dui tempor, aptent non. Quisque turpis.
                    Phasellus quis lectus luctus orci eget rhoncus. Amet donec
                    vestibulum mattis commodo, nulla aliquet, nibh praesent,
                    elementum nulla. Sit lacus pharetra tempus magna neque
                    pellentesque, nulla vel erat. Justo ex quisque nulla
                    accusamus venenatis, sed quis.
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green-darken-1"
                      variant="text"
                      @click="dialog = false"
                    >
                      Disagree
                    </v-btn>
                    <v-btn
                      color="green-darken-1"
                      variant="text"
                      @click="dialog = false"
                    >
                      Agree
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-btn color="primary" @click="dialog = true">
                Open Dialog
              </v-btn>
            </v-row>
          </div>

          <div class="aside-section" style="background-color: #1664c0">
            <h1>School Report</h1>
            <p>
              Nulla vitae elit libero, a pharetra augue. Nulla vitae elit
              libero, a pharetra augue. Nulla vitae elit libero, a pharetra
              augue. Donec sed odio dui. Etiam porta sem malesuada.
            </p>
          </div>

          <div class="aside-section">
            <h1>Annual Report</h1>
            <p>
              Nulla vitae elit libero, a pharetra augue. Nulla vitae elit
              libero, a pharetra augue. Nulla vitae elit libero, a pharetra
              augue. Donec sed odio dui. Etiam porta sem malesuada.
            </p>
          </div>
        </div> -->
        <!-- </div> -->
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      // contents: [],
      selectedContent: {},
    };
  },
  mounted() {
    this.getContent();
  },

  methods: {
    getContent() {
      let id = this.$route.params.id;
      this.$rest
        .get("/api/content-management-detail/" + id)
        .then(({ data }) => {
          this.selectedContent = data;
        });
      // console.log("here");
    },
    // openVideo(content) {
    //   this.selectedContent = content;
    //   this.videoIsOpen = !this.videoIsOpen;
    // },
  },
};
</script>
<style lang="scss">
// .layout {
//   display: block;
// }
.blog {
  margin: 0px auto;
  .blog-section-content {
    .blog-title {
      margin-bottom: 20px;
      h1 {
        // margin-bottom: 20px;
        font-size: 48px;
        font-weight: bold;
        font-family: Roboto, sans-serif;
      }
    }
    .blog-image {
      height: 550px;
      width: 100%;
      img {
        border-radius: 10px;
        height: 100%;
        width: 100%;
      }
      p {
        margin-top: 10px;
        font-size: 13px;
        text-transform: uppercase;
        margin-bottom: 40px;
        font-weight: bold;
        color: #6e6d6d;
        margin-bottom: 180px;
      }
    }
    .blog-contact-details {
      margin-top: 110px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .contact-content {
        display: flex;
        .contact-img {
          width: 80px;
          height: 50px;
          border-radius: 50%;
          img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
        .contact-info {
          margin-top: 10px;
          .details-content-name {
            width: 200px;
            h1 {
              font-size: 15px;
              font-weight: 500;
              margin-bottom: 4px;
            }
            .contact-time {
              display: flex;
              justify-content: space-between;
              p {
                margin: 0px;
                color: #888686;
                font-size: 13px;
                font-weight: 500;
              }
              .colored-text {
                color: #138b1c;
              }
            }
          }
        }
      }
      .detail-icon {
        margin-top: 10px;
        .fa-bookmark {
          font-size: 20px;
          color: #888686;
        }
      }
    }
    .blog-details {
      margin-top: 40px;
      .blog-details-title {
        h1 {
          font-size: 30px;
          font-weight: 600;
          margin-bottom: 14px;
        }
        p {
          font-size: 24px;
          color: #4b4a4a;
          line-height: 1.7;
        }
        .detail-paragraph {
          margin-top: 40px;
        }
      }
    }
    .aside-section {
      background-color: #009a70;
      padding: 34px 30px;
      border-radius: 15px;
      margin-bottom: 20px;
      h1 {
        font-size: 24px;
        font-weight: 900;
        color: #fff;
        margin-bottom: 25px;
      }
      p {
        font-size: 16px;
        color: #fff;
        line-height: 1.9;
      }
    }
  }
}
</style>
